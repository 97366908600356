import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const routes = [{
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/Register.vue')
  },
  {
    path: '/helperCN',
    name: 'helpercn',
    component: () => import('@/HelperCN.vue')
  },
  {
    path: '/helperEN',
    name: 'helperen',
    component: () => import('@/HelperEN.vue')
  },
  {
    path: '/helperFR',
    name: 'helperfr',
    component: () => import('@/HelperFR.vue')
  },
  {
    path: '/chargerbk/:id',
    name: 'chargerbk',
    component: () => import('@/Chargerbk.vue')
  },
  {
    path: '/chargerev/:id',
    name: 'chargerev',
    component: () => import('@/Chargerev.vue')
  },
  {
    path: '/admdevices0',
    name: 'admdevices0',
    meta: { requiresAdmin:true },
    component: () => import('@/Admdevices0.vue')
  },
  {
    path: '/admdevices1',
    name: 'admdevices1',
    meta: { requiresAdmin:true },
    component: () => import('@/Admdevices1.vue')
  },
  {
    path: '/admstores',
    name: 'admstores',
    meta: { requiresAdmin:true },
    component: () => import('@/Admstores.vue')
  },
  {
    path: '/admusers',
    name: 'admusers',
    meta: { requiresAdmin:true },
    component: () => import('@/Admusers.vue')
  },
  {
    path: '/admbill0',
    name: 'admbill0',
    meta: { requiresAdmin:true },
    component: () => import('@/Admbill0.vue')
  },
  {
    path: '/admbill1',
    name: 'admbill1',
    meta: { requiresAdmin:true },
    component: () => import('@/Admbill1.vue')
  },
  {
    path: '/admprepays',
    name: 'admprepays',
    meta: { requiresAdmin:true },
    component: () => import('@/Admprepays.vue')
  },
  {
    path: '/admlogs',
    name: 'admlogs',
    meta: { requiresAdmin:true },
    component: () => import('@/Admlogs.vue')
  },
  {
    path: '/admvcards',
    name: 'admvcards',
    meta: { requiresAdmin:true },
    component: () => import('@/Admvcards.vue')
  },
];

const router = new VueRouter({
  routes
});

router.beforeEach(async(to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    let lotoken = localStorage.getItem('token');
    if (lotoken) {
      let params = 'token=' + lotoken;
      let axresp = await Vue.axios.post('/auth?tm='+new Date().getTime(), params);
      if (axresp.status==200) {
        next();
      } else {
        localStorage.removeItem('nickname');
        localStorage.removeItem('token');
        next({ path:'/login', params:{ nextUrl:to.fullPath } });
      }
    } else {
      next({ path:'/login', params:{ nextUrl:to.fullPath } });
    }
  } else {
    next();
  }
});

export default router
