export const  message = {

  'languageto': 'EN',
  'Change_Language_To':'Change Language To: ',

  'login_uid': '个人邮箱(注册时填写)',
  'login_uid2': '个人邮箱(最少5字符)',
  'login_passwd': '登陆密码,最少6位数字字母组合',
  'login_save_pwd': '记住我(请勿在公共场合使用)',
  'login_btn_now': '立即登陆',
  'login_btn_register': '新用户注册',
  'login_btn_help': '帮助说明',
  'login_uid_too_short': '登陆用户名太短！',
  'login_pas_too_short': '登陆密码太短！',
  'login_register_now': '提交注册',
  'login_repasswd': '重复密码',
  'login_nickname': '账户昵称(最少3个字)',
  'register_nickname_too_short': '用户昵称太短',
  'registerpass_diff': '两次密码输入不一致',
  'register_exists': '账户已存在,请直接登陆',
  'uid_invalidemail': '请以电子邮件作为登陆账号',
  'register_OK': '账号注册成功,请登陆系统',
  'login_btn_login': '返回登陆',
  'btn_login': '账号登陆',
  'btn_prepay': '账户充值',
  'Free': '空闲中',
  'SwitchON': '已通电',
  'Charging': '充电中',
  'Disabled': '已禁用',
  'StopDN': '急停中',
  'Offline': '离线中',
  'noID': '未扫码',
  'Ready': '已就绪',
  'ChargerID:': '充电桩编号：',
  'payfullname':'用户全名：',
  'paystackaccount':'paystack账号：',
  'paymoneys':'充值金额：',
  'hpayfullname':'你的真实全名',
  'topup': ' 充值 ',
  'logout': ' 退出 ',
  'ChargeTIME': '充电时长',
  'hors': '小时',
  'bkchargehours': '请选择充电时长',
  'ChargeNOW': '启动充电',
  'DoCharge': '开始充电',
  'StopNOW': '立即停止',
  'Charging': '正在充电',
  'IamCharging': '我在充电中',
  'PortDisabled': '端口已禁用',
  'StopKeyDown': '急停已按下',
  'Clearme': '清理我的会话',
  'DeviceOffline': '设备已离线',
  'InvalidChargerID': '无效的电桩编号',
  'Starting': '正在启动',
  'Stoping': '正在停止',
  'hpaystackaccount':'你在paystack上的账户ID',
  'acTempture':'温度',
  'acState':'状态',
  'acPower':'电表(度)',
  'acCurrency':'电流(安)',
  'leftgun':'左枪',
  'rightgun':'右枪',
  'lowprize':'最低收费',
  'powerunit':'元/度',
  'm_admusers':'用户管理',
  'm_admdevices': '设备管理',
  'm_admdevices0':'单车桩管理',
  'm_admdevices1':'汽车桩管理',
  'm_admstores':'库存管理',
  'm_admprepays':'账户充值',
  'm_admchgpays':'充电消费',
  'm_admchgpays0':'单车消费',
  'm_admchgpays1':'汽车消费',
  'm_admmore':'更多',
  'm_admsetup':'设置',
  'm_admlogs':'系统日志',
  'logshuman':'用户日志',
  'logsdevice':'设备日志',
  'btn_prevpage':'上页',
  'btn_nextpage':'下页',
  'opt_all':'全部',
  'opt_ofline':'离线',
  'opt_online':'在线',
  'DataTime': '数据时间',
  'OffTime': '离线时间',
  'PowUse':'电表电量',
  'Dev':'设备',
  'Tempr':'温度',
  'Vol&Cur':'电压电流',
  'STOP': '急停',
  'OPEN': '开箱',
  'localtime':'本地时间',
  'gnderror': '接地不良',
  'swon':'已通',
  'swoff':'已断',
  'swdis':'禁用',
  'ref0':'未知情况',
  'ref1':'供电超低',
  'ref2':'软狗复位',
  'ref3':'硬狗复位',
  'ref4':'软件复位',
  'ref5':'重新通电',
  'ref6':'引脚复位',
  'ref7':'供电异常',
  'ref8':'快按急停',
  'ref9':'程序退出',
  'refa':'下行中断',
  'refb':'上报被拒',
  'refc':'蜂窝设置',
  'refd':'恢复出厂',
  'refe':'后台重启',
  
  'cbk0':'单车',
  'cbk1':'交流',
  'cbk2':'直流',
  'btn_save': '保存',
  'btn_ok': '确定',
  'btn_search': '查找',
  'btn_History': '历史',
  'btn_ACT': '激活',
  'btn_BEP': '三响',
  'btn_RBT': '重启',
  'btn_Filter': '过滤',
  'reConn': '重连',
  'msto_th0': '#电桩编号',
  'msto_th1': '重启时间',
  'msto_th2': '次数',
  'msto_th3': '==相关功能==',
  'msto_th4': '当前电表读数',
  'msto_th5': '网络',
  'msto_th6': '端口',
  'msto_th7': '原因',
  'btn_ugst':'访客',
  'btn_uvip':'贵宾',
  'btn_prox':'代理',
  'btn_umgr':'管理',
  'btn_ublk':'黑户',
  'btn_cancelpay': '取消',

  usr_th0:'用户ID',
  usr_th1:'用户昵称',
  usr_th2:'余额',
  usr_th3:'赠送',
  usr_th4:'注册时间',
  usr_th5:'到访时间',
  usr_th6:'来访IP',
  usr_th7:'充电次数',
  usr_th8:'充值数',
  usr_th9:'断电电流',
  LeftEnergy:'左枪电量',
  RightEnergy:'右枪电量',
  'Disabled(left)': '左枪停用',
  'Disabled(right)': '右枪停用',
  Switch:'开关',
  User:'客户',
  CurrState:'电流状态',
  ProtoVol:'协议电压',
  
  bkResons0: '不会发生',
  bkResons1: '未接设备',
  bkResons2: '到时停充',
  bkResons3: '插头拔出',
  bkResons4: '功率超限',
  bkResons5: '余额不足',
  bkResons6: '主线过细',
  bkResons7: '充电完成',
  bkResons8: '温度超限',
  bkResons9: '主动停止',
  bkResonsa: '完成充电',

  evResons0: '不会发生',
  evResons1: '接地不良',
  evResons2: '到时自停',
  evResons3: '拔枪停充',
  evResons4: '充电完成',
  evResons5: '余额不足',
  evResons6: '功率超限',
  evResons7: '充电完成',
  evResons8: '高温超限',
  evResons9: '主动停止',
  evResonsa: '完成充电',

  admbill_userid: '用户账号',
  admbill_nickname: '用户昵称',
  admbill_paydate: '付款日期',
  admbill_EndTime: '结束时间',
  admbill_StartTime: '开始时间',
  admbill_billmoney: '账单金额',
  admbill_reallmoney: '实付金额',
  admbill_charger_port: '电桩插座',
  admbill_reason: '断电原因',
  admbill_usage: '计划,实际,功率',
  admbill_energy: '抄表电量',
  admbill_energyuse: '本次用电',
  admbill_power: '平均功率',
  prepay_happen: '充值日期',
  prepay_amount: '充值金额',
  prepay_thisbalance: '本次余额',
  prepay_cash: '现金支付',
  prepay_paystack: 'PayStack',
  prepay_paypal: 'PayPal',
  logs_evbk: '汽单',
  logs_happen: '发生时间',
  logs_uidmac: '用户/设备',
  logs_oper: '操作',
  logs_remoteip: 'IP',
  logs_desc: '日志描述',
  
  'tabpaystack': 'PayStack',
  'tabvcard': '虚拟卡',
  'tabpaypal': 'PayPal',
  'paypalmount': '充值金额',
  'm_admvcards':'虚拟卡管理',
  'vcardactivenow': '立即激活',
  'vcardactiveer1': '请输入数字',
  'vcardactiveer2': '需要4位数字',
  'vcardactiveer3': '需要8位数字',
  'vcardactiveer4': '请填写用户账号',
  'vcardactiveer5': '用户账号不是邮箱',
  'activetarget': '邮箱账号',
  'activecode': '激活码',
  'btn_vcard_download': '下载',
  

  
}