

// aws apigateway get-rest-apis --query "items[?name=='a'].id" --output text
export const BASE = "https://vo5qdwk2bg.execute-api.eu-north-1.amazonaws.com/a";

// @see https://www.google.com/recaptcha/about/
export const recaptchasitekey = '6LdNlhwqAAAAAEQum8BOMoOnqUCtTcj8BiZzw1kM';

// @see https://paystack.com/docs/guides/accept_payments_on_your_vue_app
export const paystackpublickey = 'pk_live_bd718e3eafe0f5ff5b4eac83815cbca2b0f8f28c';

// paypal clientid used by Chargerbk and Chargerev
export const paypalClientid = 'AYg=================vnciN';


//=============================================================================

export const APPVERSION = '';
export const twodaymills = 172800000;
export const prepaylimit = 1;
export const ChargerSTATS = ['readyfree','readygunin','readywaiting','readycharging','readybadgnd','stopdown','offline'];
export const defaultpaystackid = 'payment@eddievolt.com';

if (typeof(Date.prototype.format) === "undefined") {
  Date.prototype.format = function(fmt) {
    var o = {
      'M+': this.getMonth() + 1,
      'd+': this.getDate(),
      'h+': this.getHours(),
      'm+': this.getMinutes(),
      's+': this.getSeconds()
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substring(4 - RegExp.$1.length));
    for (var k in o)
      if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' +
        o[k]).substring(('' + o[k]).length)));
    return fmt;
  };
}
if (typeof(Array.prototype.contains) === "undefined") {
    Array.prototype.contains = function (obj) {
        let i = this.length;
        while (i--) {
            if (this[i] === obj) {
                return true;
            }
        }
        return false;
    };
}
if (typeof(Array.prototype.removeone) === "undefined") {
    Array.prototype.removeone = function (val) {
        let index = -1;
        for (let i = 0; i < this.length; i++) {
            if (this[i] == val) {
                index = i;
                break;
            }
        }
        if (index > -1) {
            this.splice(index, 1);
        }
        return index;
    };
}
export function isRunningLocally() {
  return process.env.NODE_ENV !== 'production';
}

export function getApiEndpoint(path) {
  const base = isRunningLocally() ? process.env.API_GATEWAY_DEV : process.env.API_GATEWAY_PROD;
  return `${base}/${path}`;
}

/**
 * Updates API requests to use the correct endpoint based on the environment.
 */
export function updateApiEndpoints() {
  // Find all calls to this.axios.post or this.axios.get
  const apiCalls = document.evaluate("//CallExpression[MemberExpression/Identifier[@name='axios']][MemberExpression/Identifier[@name='post' or @name='get']]", document, null, XPathResult.UNORDERED_NODE_SNAPSHOT_TYPE, null);

  for (let i = 0; i < apiCalls.snapshotLength; i++) {
    const apiCall = apiCalls.snapshotItem(i);
    const pathArg = apiCall.arguments[0];
    
    if (pathArg && pathArg.raw) {
      const path = pathArg.raw.slice(1, -1); // Remove quotes
      pathArg.raw = `'${getApiEndpoint(path)}'`;
    }
  }
}
