export const  message = {

  'languageto': 'Fr',

  'Change_Language_To':'Change language to: ',

  'login_uid': 'email ',
  'login_uid2': 'email  ',
  'login_passwd': 'password ',

  'login_save_pwd': 'Save password (Never in public)',
  'login_btn_now': 'Login',
  'login_btn_register': 'Register',
  'login_btn_help': 'Help',
  'login_uid_too_short': 'Username too short !',
  'login_pas_too_short': 'Password too short !',
  'login_register_now': 'Register ',
  'login_repasswd': 'repeat password',
  'login_nickname': 'nickname',
  'register_nickname_too_short': 'nickname too short !',

  'registerpass_diff': 'Passwords do not match !',
  'register_exists': 'Account exists, Please Login',
  'register_OK': 'Registration successful!',
  'uid_invalidemail': 'invalid email address',

  'login_btn_login': 'Back to Login',
  'btn_login': 'Login',
  'btn_prepay': 'Top Up',
  'Free': 'Free',
  'SwitchON': 'SwitchON',
  'Charging': 'Charging',
  'Disabled': 'Disabled',
  'StopDN': 'StopDown',
  'Offline': 'Offline',
  'noID': 'noID',
  'Ready': 'Ready',
  'ChargerID:': 'HubID: ',
  'payfullname':'Full name: ',
  'paystackaccount':'Email: ',
  'paymoneys':'Amount: ',
  'topup': ' Top Up ',
  'logout': ' Log Out ',
  'ChargeTIME': 'Duration',
  'hors': 'hrs',
  'hor': 'hr',
  'mins': 'min',
  'bkchargehours': 'Select charge duration',
  'ChargeNOW': 'Charge',
  'DoCharge': 'Start Charge',
  'StopNOW': 'Stop',
  'Charging': 'Charging',
  'IamCharging': 'Is Charging',
  'PortDisabled': 'Socket Disabled',
  'StopKeyDown': 'StopKey ShutDown',
  'Clearme': 'Clear',
  'DeviceOffline': 'Device Offline',
  'InvalidChargerID': 'Invalid HubID',
  'Starting': 'Starting',
  'Stoping': 'Stopping',
  'hpayfullname':'Your full name',
  'hpaystackaccount':'Your email',
  'acTempture':'Tempr',
  'acState':'Status',
  'acPower':'Energy(kWh)',
  'acCurrency':'Currency(Amp)',
  'leftgun':'LeftGun',
  'rightgun':'RightGun',
  'lowprize':'StartPrize',
  'powerunit':'GH/kWh',
  'm_admusers':'Users',
  'm_admdevices': 'Devices',
  'm_admdevices0':'BK Devices',
  'm_admdevices1':'EV Devices',
  'm_admstores':'Dev Stores',
  'm_admprepays':'Fee Prepays',
  'm_admchgpays':'Bills',
  'm_admchgpays0':'BK Bills',
  'm_admchgpays1':'EV Bills',
  'm_admmore':'More',
  'm_admsetup':'Setup',
  'm_admlogs':'SysLogs',
  'logshuman':'Usrlog',
  'logsdevice':'Devlog',
  'btn_prevpage':'Prev',
  'btn_nextpage':'Next',
  'opt_all':'ALL',
  'opt_ofline':'Offline',
  'opt_online':'Online',
  'DataTime': 'DataTime',
  'OffTime': 'OfflineTime',
  'PowUse':'PowUse',
  'Dev':'Dev',
  'Tempr':'Tempr',
  'Vol&Cur':'Vol&Cur',
  'STOP': 'STOP',
  'OPEN': '&OPEN',
  'localtime':'LocalTime',
  'gnderror': 'GNDError',
  'swon':'ON ',
  'swoff':'OFF',
  'swdis':'DIS',
  'ref0':'UNKON',
  'ref1':'LOWPW',
  'ref2':'WWDOG',
  'ref3':'IWDOG',
  'ref4':'RESET',
  'ref5':'POWON',
  'ref6':'MCUPN',
  'ref7':'BWNOU',
  'ref8':'STOPd',
  'ref9':'EXITd',
  'refa':'REJET',
  'refb':'RPTER',
  'refc':'LTEER',
  'refd':'RESET',
  'refe':'SERVR',
  
  'cbk0':'BK',
  'cbk1':'AC',
  'cbk2':'DC',
  'btn_save': 'Save',
  'btn_ok': 'OK',
  'btn_search': 'Search',
  'btn_History': 'History',
  'btn_ACT': 'ACT',
  'btn_BEP': 'BEP',
  'btn_RBT': 'RBT',
  'btn_Filter': 'Filter',
  'reConn': 'reConn',
  'msto_th0': '#ChargerID',
  'msto_th1': 'Reboot',
  'msto_th2': 'Times',
  'msto_th3': '= Function Buttons =',
  'msto_th4': 'EnergyUsed',
  'msto_th5': 'Net',
  'msto_th6': 'Pos',
  'msto_th7': 'Reason',
  'btn_ugst':'Guest',
  'btn_uvip':'VIP',
  'btn_prox':'proxy',
  'btn_umgr':'Manager',
  'btn_ublk':'Black',
  'btn_cancelpay': 'Cancel',
  
  usr_th0:'userid',
  usr_th1:'nickname',
  usr_th2:'Balance',
  usr_th3:'Gift',
  usr_th4:'RegTime',
  usr_th5:'active',
  usr_th6:'VistIP',
  usr_th7:'ChgTMS',
  usr_th8:'ReCh',
  usr_th9:'OffCurrent',
  
  LeftEnergy:'LeftEnergy',
  RightEnergy:'RightEnergy',
  'Disabled(left)': 'Disabled(left)',
  'Disabled(right)': 'Disabled(right)',
  Switch:'Switch',
  User:'User',
  CurrState:'CurrState',
  ProtoVol:'ProtoVol',
  
  bkResons0: 'SysSTOP', /*no use for device, never happen in normal work*/
  bkResons1: 'NoChgDev',/*No charger device pluged into socket*/
  bkResons2: 'PlanSTOP',
  bkResons3: 'plugOUT',/*Direct plug out the socket, (Currency change down fast)*/
  bkResons4: 'PowLimit',/*two high power to stop, 900 watt or more*/
  bkResons5: 'FeeLimit',/*user account is too lower*/
  bkResons6: 'HiVoLoss',/*Too thin wire leads to serious voltage drop, force close*/
  bkResons7: 'ChgOVER', /*Charge Finished confirmed by Server Settings (currency lower than 0.04~0.4), each person can be diffrent and setted in UserManagement*/
  bkResons8: 'HiTempr', /*Too high tempture to close charging*/
  bkResons9: 'userSTOP',/*stop button pressed by user*/
  bkResonsa: 'ChgFinish',/*Device say 1 (nodevice), but session has payed for more than 30 minutes, then change to 10*/

  evResons0: 'ChgOver', /*never happen in normal work*/
  evResons1: 'GNDerr',  /*NoGNDconnected*/
  evResons2: 'TimeLimit', /*no use for EV*/
  evResons3: 'gunOUT',    /*gun pluged out directory*/
  evResons4: 'gunSTOP',   /*CP change to 9, but not pluged out*/
  evResons5: 'FeeLimit',  /*user account is too lower*/
  evResons6: 'PowHigh',   /*Currency Higher to 120% of limit*/
  evResons7: 'ChgOVER',   /*When use as BIKER Charger, lower currency auto off*/
  evResons8: 'HiTempr',  /*Too high tempture to close charging*/
  evResons9: 'userSTOP',/*stop button pressed by user*/
  evResonsa: 'ChgFinish', /*when reson 3 and reson4 happened, recheck CP after 60s, when CP==9 change reason to 10*/
  
  admbill_userid: 'userid',
  admbill_nickname: 'nickname',
  admbill_paydate: 'paydate',
  admbill_EndTime: 'EndTime',
  admbill_StartTime: 'StartTime',
  admbill_billmoney: 'billmoney',
  admbill_reallmoney: 'reallmoney',
  admbill_charger_port: 'Charger#',
  admbill_reason: 'Reason',
  admbill_usage: 'plan,fact,power',
  admbill_energy: 'DevEnergy',
  admbill_energyuse: 'UsedEnergy',
  admbill_power: 'Power',
  prepay_happen: 'happen',
  prepay_amount: 'amount',
  prepay_thisbalance: 'thisbalance',
  prepay_cash: 'Cash',
  prepay_paystack: 'PayStack',
  prepay_paypal: 'PayPal',
  logs_evbk: 'evbk',
  logs_happen: 'happen',
  logs_uidmac: 'uid/mac',
  logs_oper: 'oper',
  logs_remoteip: 'remoteip',
  logs_desc: 'desc',
  
  'tabpaystack': 'Online Payment',
  'tabvcard': 'Voucher Code',
  'tabpaypal': 'PayPal',
  'paypalmount': 'Amount',
  'm_admvcards':'virtualCards',
  'vcardactivenow': 'Activate',
  'vcardactiveer1': 'Input Number please',
  'vcardactiveer2': '4 digits number needed',
  'vcardactiveer3': '8 digits number needed',
  'vcardactiveer4': 'Input user account please',
  'vcardactiveer5': 'Invalid email address',
  'activetarget': 'active user account',
  'activecode': 'Voucher Code',
  'btn_vcard_download': 'Downloads',
  
  
  
  
  
  

}