<template>
  <b-navbar toggleable="sm" variant="faded mb-3">
    <b-navbar-brand>
      <b-img src="/logo.png" fluid></b-img>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"><b-icon icon="chevron-bar-down"></b-icon>
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="admusers">{{'m_admusers'|trans}}</b-nav-item>
        <b-nav-item-dropdown :text="'m_admdevices'|trans">
          <b-dropdown-item to="admdevices0" class="p-1">{{'m_admdevices0'|trans}}</b-dropdown-item>
          <b-dropdown-item to="admdevices1" class="p-1">{{'m_admdevices1'|trans}}</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item to="admstores" class="p-1">{{'m_admstores'|trans}}</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item to="admprepays">{{'m_admprepays'|trans}}</b-nav-item>
        <b-nav-item-dropdown :text="'m_admchgpays'|trans">
          <b-dropdown-item to="admbill0" class="p-1">{{'m_admchgpays0'|trans}}</b-dropdown-item>
          <b-dropdown-item to="admbill1" class="p-1">{{'m_admchgpays1'|trans}}</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown :text="'m_admmore'|trans">
          <b-dropdown-item to="admlogs" class="p-1">{{'m_admlogs'|trans}}</b-dropdown-item>
          <b-dropdown-item to="admvcards" class="p-1">{{'m_admvcards'|trans}}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item @click="logout" class="d-none d-md-block">
          <b-icon icon="power" variant="danger"></b-icon>
        </b-nav-item>
        <b-nav-item class="d-none d-lg-block greyco">({{nickname}})</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
  export default {
    name: 'MainMenu',
    data() {
      return {
        uid: localStorage.uid,
        nickname: localStorage.nickname
      };
    },
    methods: {
      logout() {
        localStorage.removeItem('nickname');
        localStorage.removeItem('token');
        localStorage.removeItem('spw');
        localStorage.removeItem('uid');
        localStorage.removeItem('pas');
        this.$router.push('login');
      },
    }
  }
</script>
